define("ember-svg-jar/inlined/diagonal-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_1_2)\"><circle cx=\"6\" cy=\"6\" r=\"6\" fill=\"#fff\"/><path stroke=\"#000\" d=\"M-20.646-10.747L33.801 43.7M-16.404-14.99l54.447 54.448M-12.161-19.232l54.447 54.447M-14.283-17.111l54.448 54.447M-10.04-21.354l54.447 54.448M-18.525-12.868l54.447 54.447M10.354-.747L64.801 53.7M14.596-4.99l54.447 54.448M12.475-2.868l54.447 54.447M-16.646 14.253L37.801 68.7M-12.404 10.01l54.447 54.448M-8.161 5.768l54.447 54.447M-10.283 7.889l54.448 54.447M-6.04 3.646l54.447 54.448M-14.525 12.132l54.447 54.447\"/><circle cx=\"6\" cy=\"6\" r=\"6\" stroke=\"#000\" stroke-width=\"1.5\"/></g><defs><clipPath id=\"clip0_1_2\"><circle cx=\"6\" cy=\"6\" r=\"6\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});